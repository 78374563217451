import type { SchemaField, useForm } from '@tarcltd/form-vue'

/**
 * Sorts the fields in the form input schema definition.
 *
 * @param input - The form input schema definition.
 * @returns A sorted array of fields.
 */
export default function useDynamicFormFieldSort(
  input: ReturnType<typeof useForm>['input'],
): [string, SchemaField][] {
  return Object.entries(input.properties ?? {}).sort(
    ([_a, a], [_b, b]) => a.attrs?.order - b.attrs?.order,
  ) as [string, SchemaField][]
}
